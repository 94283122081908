import { AnyAction } from "redux";
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from "..";
import { User } from "src/types/generated/user";
import { UserBasicInfoDto } from "src/types/generated/userBasicInfoDto";

//Action Types
export enum AuthActionType {
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_LOADING = 'UPDATE_LOADING',
  UPDATE_TOKEN = 'UPDATE_TOKEN',
}

export type AuthAction = AnyAction | UpdateUserAction | UpdateLoadingAction;

export interface UpdateUserAction extends AnyAction {
  type: AuthActionType.UPDATE_USER;
  payload: UserBasicInfoDto;
}

export interface UpdateLoadingAction extends AnyAction {
  type: AuthActionType.UPDATE_LOADING;
  payload: boolean;
}

export interface AuthState {
  loading: boolean;
  user?: UserBasicInfoDto;
  token?: string;
}

export const initialState: AuthState = {
  loading: true,
};

export const useAuth = () => {
  const user = useSelector<ApplicationState, UserBasicInfoDto>(state => state.auth.user);
  const token = useSelector<ApplicationState, string>(state => state.auth.token);
  const loading = useSelector<ApplicationState, boolean>(state => state.auth.loading);
  const dispatch = useDispatch();

  const updateUser = (payload: UserBasicInfoDto) => {
    dispatch({
      type: AuthActionType.UPDATE_USER,
      payload,
    });
  };

  const updateLoading = (payload: boolean) => {
    dispatch({
      type: AuthActionType.UPDATE_LOADING,
      payload,
    });
  };

  const updateToken = (payload: string) => {
    dispatch({
      type: AuthActionType.UPDATE_TOKEN,
      payload,
    });
  };

  return {
    user, updateUser, loading, updateLoading, updateToken, token,
  };
};

const authReducer = (state: AuthState = initialState, action: AuthAction) => {
  switch (action.type) {
    case AuthActionType.UPDATE_USER:
      return {
        ...state,
        user: action.payload,
        ...(!action.payload && { token: undefined }), // If user is undefined also token is removed
      };
    case AuthActionType.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload,
        ...(!action.payload && { user: undefined }), // If token is undefined also user is removed
      };
    case AuthActionType.UPDATE_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return { ...state };
  }
};

export default authReducer;