import React from 'react';
import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import Head from 'next/head';
import { createWrapper, MakeStore } from 'next-redux-wrapper';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import { QueryClient, QueryClientProvider } from 'react-query';

import theme from '../theme';
import { initializeStore } from 'src/redux/store';
import App from 'next/app';
import { WithStore } from 'src/redux';
import { NavigationPath } from 'src/types/navigation';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const queryClient = new QueryClient();

// create a makeStore function
const makeStore: MakeStore<any> = () => initializeStore(); // interface ApplicationState

// export an assembled wrapper 
export const wrapper = createWrapper<any>(makeStore, { debug: false }); // interface ApplicationState

class MyApp extends App<WithStore> {
  public async componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  public render() {
    const { Component, pageProps } = this.props;

    /**
     * If viewer update router
     */
    if (this.props.router.asPath.includes(NavigationPath.VIEWER) &&
      !this.props.router.basePath.includes(NavigationPath.VIEWER) &&
      !this.props.router.route.includes(NavigationPath.VIEWER)
    ) {
      let id = this.props.router.asPath.split(/\//)[2];
      this.props.router.push(`/viewer/${id}`);
      return null;
    }

    if (this.props.router.asPath.includes(NavigationPath.PRESENTATIONS) &&
      !this.props.router.basePath.includes(NavigationPath.PRESENTATIONS) &&
      !this.props.router.route.includes(NavigationPath.PRESENTATIONS)
    ) {
      let id = this.props.router.asPath.split(/\//)[2] ?? '';
      this.props.router.push(`/presentations/${id}`);
      return null;
    }

    if (this.props.router.asPath.includes(NavigationPath.RESET_PASSWORD) &&
      !this.props.router.basePath.includes(NavigationPath.RESET_PASSWORD) &&
      !this.props.router.route.includes(NavigationPath.RESET_PASSWORD)
    ) {
      let token = this.props.router.asPath.split(/\//)[2];
      this.props.router.push(`/resetPassword/${token}`);
      return null;
    }

    return <>
      <Head>
        <title>Safetycon360</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <Component {...pageProps} />
          </QueryClientProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>;
  }
}

export default wrapper.withRedux(MyApp);