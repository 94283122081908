import { combineReducers, AnyAction, Store } from 'redux';
import authReducer, { AuthAction } from './reducers/authReducer';

const rootReducer = combineReducers({
  auth: authReducer,
});

const generateInitialState = (): ApplicationState => {
  const result = {} as ApplicationState;
  Object.keys(rootReducer).forEach(key => {
    result[key] = rootReducer[key](undefined, { type: 'test' });
  });
  return result;
};

export type AppAction = AnyAction | AuthAction;

export type ApplicationState = ReturnType<typeof rootReducer>;

export interface WithStore {
  store: Store<ApplicationState, AppAction>;
}

export const storeInitialState = generateInitialState();

export default rootReducer;