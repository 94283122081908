import { red } from "@mui/material/colors";
import { createTheme, Theme } from "@mui/material/styles";

export interface ThemeProps {
  theme: Theme;
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#222",
    },
    secondary: {
      main: "#83CE89",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
});

export default theme;
