export enum NavigationPath {
  HOME = "/",

  LOGIN = "/login",
  REGISTER = "/signup",
  LOG_OUT = "/logout",
  PRESENTATIONS = "/presentations",
  USERS = "/users",
  PROFILE = "/profile",
  RESET_PASSWORD = "/resetPassword",
  VIEWER = "/viewer",
  ORGANIZATIONS = "/organizations",
  MATERIALS = "/materials"
}